import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Grid2,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import React, { useEffect, useState } from "react";
import MarathonNavbar from "../layouts/MarathonNavbar";
// import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import i18n from "../../i18n/config";
import { GrLanguage } from "react-icons/gr";
// import { FaFacebook, FaXTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FaPhone, FaXTwitter } from "react-icons/fa6";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const MarathonHome = () => {
  // const { register, setValue } = useForm({});
  const { t, i18n } = useTranslation();
  useEffect(() => {
    Aos.init();
  }, []);
  useEffect(() => {
    let lang = localStorage.getItem("lang");
    if (lang === null) {
      // setValue("lange", "ta");
    } else {
      // setValue("lange", lang);
    }
  }, []);

  const lang = localStorage.getItem("lang");
  useEffect(() => {
    const savedLang = localStorage.getItem("lang") || "ta";
    // setValue("lange", savedLang);
    i18n.changeLanguage(savedLang);
    document.documentElement.lang = savedLang;
  }, [i18n]);

  const changeLanguage = (e) => {
    const selectedLang = e.target.value;
    i18n.changeLanguage(selectedLang);
    localStorage.setItem("lang", selectedLang);
    document.documentElement.lang = selectedLang;
  };

  const cardData = [
    {
      title: `${t("Homepage.event3")}`,
      // subtitle: `${t("Homepage.eventtitle3")}`,
      description: `${t("Homepage.description2")}`,
      image: "/images/event-1.png",
      date: "NOVEMBER 2, 2018",
      location: "Los Angeles",
    },
    {
      title: `${t("Homepage.event2")}`,
      // subtitle: `${t("Homepage.eventtitle2")}`,
      description: `${t("Homepage.description1")}`,
      image: "/images/event-2.png",
      date: "NOVEMBER 15, 2018",
      location: "Los Angeles",
    },
    {
      title: `${t("Homepage.event1")}`,
      // subtitle: `${t("Homepage.eventtitle1")}`,
      description: `${t("Homepage.description")}`,
      image: "/images/event-3.png",
      date: "DECEMBER 2, 2018",
      location: "Los Angeles",
    },
  ];
  const tamilTermsConditions = [
    {
      condition:
        "உங்களுக்கான நிகழ்வை கவனமாகத் தேர்ந்தெடுக்கவும், உறுதிப்படுத்தப்பட்ட பதிவுகள் திரும்பப் பெறப்படாது. பதிவுசெய்த பிறகு நிகழ்வு வகையை மாற்ற இயலாது. நிகழ்வுக்கான குறைந்தபட்ச வயது தகுதி பின்வருமாறு:",
      conditionSub: [
        "05 கிமீ: 12 ஆண்டுகள் (2012 டிசம்பர் 29 அல்லது அதற்கு முன் பிறந்தவர்கள்)",
        "10 கிமீ: 16 ஆண்டுகள் (ஜனவரி 5, 2008 அன்று அல்லது அதற்கு முன் பிறந்தவர்கள்)",
      ],
    },
    {
      condition:
        "அனைத்து பங்கேற்பாளர்களும் தங்கள் BIB ஐ சேகரிக்கும் போது வயதுச் சான்று சமர்ப்பிக்க வேண்டும். 18 வயதிற்குட்பட்ட ஓட்டப்பந்தய வீரர்கள், BIB  சேகரிப்பின் போது பெற்றோர் அல்லது பாதுகாவலரின் எழுத்துப்பூர்வ ஒப்புதலை சமர்ப்பிக்க வேண்டும்.",
    },
    {
      condition:
        "நிகழ்வின் போது உங்களைத் தொடர்புகொள்வதற்கான எங்களின்  முதன்மை வழிமுறையாக மின்னஞ்சல் தொடர்பு இருக்கும் என்பதால், நீங்கள் தொடர்ந்து அணுகக்கூடிய பாதுகாப்பான மின்னஞ்சல் முகவரியை எங்களுக்கு வழங்கவும். தெரியாத மின்னஞ்சல் முகவரிகளில் இருந்து வரும் செய்திகளை வடிகட்டுதல் / தடுப்பதை மின்னஞ்சல் சேவைகளின் பயனர்கள் தங்கள் முகவரி பட்டியலில் vanakkam@thamizhthadam.org ஐ சேர்க்க வேண்டும்.",
    },
    {
      condition:
        "அமைப்பாளர்கள்,  உங்களை மின்னஞ்சல் மூலம் மட்டுமே தொடர்புகொள்வார்கள். அமைப்பாளர்களுடன் பதிவுசெய்யப்பட்ட மின்னஞ்சல் முகவரிக்கு அனுப்பப்படும் எந்த அறிவிப்பும், உங்களால் பெறப்பட்டதாகக் கருதப்படும்.",
    },
    {
      condition:
        "நிகழ்வில்  பதிவு செய்வதன் மூலம், நீண்ட தூர ஓட்டம் என்பது ஒரு கடுமையான விளையாட்டு மற்றும் உடலை சோர்வடைய செய்யும் என்பதை நீங்கள் அறிந்திருப்பதாக நீங்கள் ஒப்புக்கொள்கிறீர்கள். தமிழோட்டம் 2024 இல் பங்கேற்பதற்கான முழுப்பொறுப்பையும் நீங்கள் ஏற்றுக்கொள்கிறீர்கள்.",
    },
    {
      condition:
        "நிகழ்வில் பங்கேற்பதற்கான உங்கள்  உடல் தகுதியை மதிப்பிடுவதற்கு உங்கள் மருத்துவரை அணுகி முழுமையான மருத்துவ பரிசோதனைக்கு உட்படுத்துவது பரிந்துரைக்கப்படுகிறது.",
    },
    {
      condition:
        "அதிக வெப்பம் அல்லது ஈரப்பதம், போக்குவரத்து மற்றும் சாலையின் நிலை, தீ விபத்து உள்ளிட்ட வானிலையின் விளைவுகள் உட்பட இந்த நிகழ்வில் பங்கேற்பதால் ஏற்படும் அனைத்து ஆபத்துகளையும் நீங்கள் அறிந்திருப்பதை ஒப்புக்கொள்கிறீர்கள். அல்லது பயங்கரவாத அச்சுறுத்தல்கள் மற்றும் ஒரு பொது நிகழ்வுடன் தொடர்புடைய பிற ஆபத்துகள் பற்றியும் அறிந்திருப்பதை ஒப்புக்கொள்கிறீர்கள்.",
    },
    {
      condition:
        "நிகழ்வில் நீங்கள் பங்கேற்பதால் ஏற்படக்கூடிய இழப்பு, சேதம், நோய் அல்லது காயம் ஆகியவற்றிற்குத் தமிழோட்டம் 2024 ஏற்பாட்டுக் குழு, தமிழ்த்தடம் அறக்கட்டளை மற்றும் நிகழ்வை ஏற்பாடு செய்ய தொடர்புடைய நிறுவனங்கள் பொறுப்பேற்காது என்பதை ஒப்புக்கொள்கிறீர்கள்",
    },
    {
      condition:
        "உங்கள் உடல்நலம் மற்றும் நிகழ்வின் பாதுகாப்பின் நலனுக்காக அமைப்பாளர்கள் அவ்வப்போது வழங்கும் அறிவுறுத்தல்களுக்கு இணங்க ஒப்புக்கொள்கிறீர்கள்.",
    },
    {
      condition:
        "நிகழ்விற்கு ஏற்றவாறு உடை அணிய ஒப்புக்கொள்கிறீர்கள். பொருத்தமற்ற ஆடைகள், மற்ற பங்கேற்பாளர்களுக்கு சங்கடம் ஏற்படுத்தும் ஆடை அல்லது உபகரணங்கள், மற்ற பங்கேற்பாளர்களுக்கு விரும்பத்தகாத, அரசியல் அல்லது மத பிரச்சாரம் கொண்ட செய்திகளை எடுத்துச் செல்வது மற்றும் நிகழ்வு அமைப்பாளர் அங்கீகரிக்காத தனிப்பட்ட பெயர் அல்லது நிறுவனத்தை விளம்பரப்படுத்துவது  என இவை  அனைத்தும் அடங்கும்.",
    },
    {
      condition:
        "எந்த வகையிலும் அரசியல் அல்லது மதப் பிரச்சாரம் மற்றும் நிகழ்வு அமைப்பாளர் ஒப்புக்கொள்ளாத தனிப்பட்ட பெயர் அல்லது நிறுவனத்தை விளம்பரப்படுத்துவதற்கு நிகழ்வை நீங்கள் பயன்படுத்த மாட்டீர்கள் என்பதை ஒப்புக்கொள்கிறீர்கள்.",
    },
    {
      condition:
        "நிகழ்வு அமைப்பாளர்கள் அல்லது மருத்துவ ஊழியர்கள் அல்லது உதவி நிலைய தன்னார்வலர்கள் அறிவுறுத்தினால், ஓடுவதை நிறுத்த ஒப்புக்கொள்கிறீர்கள்.",
    },
    {
      condition:
        "நிகழ்வை உள்ளடக்கிய படங்கள், புகைப்படங்கள், கட்டுரைகள், பந்தயப் பதிவுகள் மற்றும் இருப்பிடத் தகவல் ஆகியவற்றின் பதிப்புரிமை மற்றும் டிவி ஒளிபரப்பு, செய்தித்தாள்கள், பத்திரிகைகள் மற்றும் இணையத்திற்கான அவற்றின் பயன்பாட்டின் உரிமை ஆகியவை நிகழ்வு அமைப்பாளருக்கே சொந்தமானது. மேற்கூறிய பதிப்புரிமை, நிகழ்வை உள்ளடக்கிய கட்டுரையில் குறிப்பிடப்பட்டுள்ள பங்கேற்பாளர்களின் வயது மற்றும் முகவரி போன்ற பெயர்கள் மற்றும் பிற தனிப்பட்ட தகவல்களை உள்ளடக்கியது. உங்கள் பங்கேற்பின் போது எடுக்கப்பட்ட உங்கள் பெயர் மற்றும் மீடியா பதிவுகள் ஏற்பாட்டாளர்களால் எந்த நேரத்திலும் நிகழ்வை விளம்பரப்படுத்த பயன்படுத்தப்படலாம் என்பதை உறுதிப்படுத்துகிறீர்கள்.",
    },
    {
      condition:
        "நிகழ்வின் புள்ளிவிவரத் தகவல்களைத் தொகுக்க உங்கள் தனிப்பட்ட தகவல்களை தமிழ்த்தடம் அறக்கட்டளை அல்லது வேறு எந்த நிறுவனமும் சேமித்து பயன்படுத்த முடியும் என்பதை நீங்கள் ஒப்புக்கொள்கிறீர்கள்.",
    },
    {
      condition:
        "பாதகமான வானிலை, எதிர்பாராத நிகழ்வுகள் அல்லது அச்சுறுத்தல்கள் ஏற்பட்டால், நிகழ்வை நிறுத்த/ரத்துசெய்ய/ ஒத்திவைக்க ஏற்பாட்டாளர்களுக்கு உரிமை உண்டு என்பதை உறுதிப்படுத்துகிறீர்கள். உறுதிப்படுத்தப்பட்ட பதிவுகள் மற்றும் விற்பனைப் பொருட்கள் ஆர்டர்கள் திரும்பப்பெற முடியாதவை, மாற்ற முடியாதவை மற்றும் அவற்றை மாற்ற முடியாது என்பதை நீங்கள் புரிந்துகொள்கிறீர்கள், ஒப்புக்கொள்கிறீர்கள்.",
    },
    {
      condition:
        "எந்தவொரு விண்ணப்பத்தையும் காரணங்களை வழங்காமல் நிராகரிக்கும் உரிமையை அமைப்பாளர்கள் கொண்டுள்ளனர். நிராகரிக்கப்பட்ட விண்ணப்பங்களுக்கு  மட்டும் வசூலிக்கப்பட்ட  தொகை முழுமையாகத் திருப்பியளிக்கப்படும் (பொருந்தக்கூடிய இடங்களில் வங்கிக் கட்டணங்கள் தவிர்த்து).",
    },
    {
      condition:
        "தமிழோட்டம் 2024 குழு, பந்தய நாளுக்கு முன் கட் ஆஃப் மற்றும் நிகழ்வு மூடும் நேரத்தைத் தெரிவிக்கும். ஒரு நிகழ்விற்கான நிர்ணயிக்கப்பட்ட கட்-ஆஃப் நேரத்திற்கு அப்பால் பங்கேற்பாளர்கள் பாதை தடத்தில் தொடர அனுமதிக்கப்பட மாட்டார்கள்.",
    },
    {
      condition:
        "உறுதிப்படுத்தப்பட்ட பங்கேற்பாளர்கள் தங்கள் BIB  ஐ சேகரிக்க Expo விற்கு வருகை தருவது கட்டாயமாகும். தவிர்க்க முடியாத காரணங்களால் ஒரு பங்கேற்பாளர் Expo வில் கலந்து கொள்ள முடியாவிட்டால், அங்கீகரிக்கப்பட்ட பிரதிநிதி அவர்கள் சார்பாக BIB ஐ சேகரிக்கலாம். இது தொடர்பான விரிவான வழிமுறைகள் அனைத்து பங்கேற்பாளர்களுக்கும் உரிய நேரத்தில் மின்னஞ்சல் மூலம் அனுப்பப்படும்.",
    },
    {
      condition:
        "பதிவு படிவத்தில் நீங்கள் வழங்கிய மொபைல் எண்ணுக்கு நாங்கள் தகவல்களை அனுப்புவோம். இது Spam ஆக கருதப்படக்கூடாது மேலும் எங்கள் மொத்த குறுஞ்செய்தி  / வாட்ஸ்அப் / மின்னஞ்சல் சேவை வழங்குநர் மற்றும் / அல்லது அமைப்பாளர்கள் மற்றும் கூட்டாளர்களுக்கு எதிராக நீங்கள் எந்த நடவடிக்கையும் எடுக்க மாட்டீர்கள் என்று ஒப்புக்கொள்கிறீர்கள்.",
    },
  ];

  const englistTermsConditions = [
    {
      condition:
        "Please choose the event carefully, confirmed registrations are non-refundable. You will not have an option to change the Event Category after registration. The minimum age eligibility for various events is as follows:",
      conditionSub: [
        "05 km: 12 years (born on or before 29th December 2012)",
        "10 km: 16 years (born on or before 5th January 2008)",
      ],
    },
    {
      condition:
        "Proof of age shall be submitted by all participants while collecting their Bib. Runners under the age of 18 years must have written consent of their Parent or Guardian which will be submitted during bib collection.",
    },
    {
      condition:
        "Please provide us with a secure email address that you can access regularly, as email communication will be our primary means of contacting you during the run up to the Event. Users of email services that offer filtering / blocking of messages from unknown email addresses should add vanakkam@thamizhthadam.org to their address list.",
    },
    {
      condition:
        "The Organizers will contact the runners only by email. Any notice sent to the email address registered with the Organizers shall be deemed as received by the runners.",
    },
    {
      condition:
        "The Organizers will contact the runners only by email. Any notice sent to the email address registered with the Organizers shall be deemed as received by the runners.",
    },
    {
      condition:
        "By registering to the events, you acknowledge and accept that you are aware that long distance running is an extreme sport and can be injurious to body and health. You take full responsibility for participating in the Thamizhottam 2024 and do not hold the Organizers of the Thamizhottam 2024, Thamizhthadam Arakkattalai or other organizing persons or entities responsible for any injury or accident.",
    },
    {
      condition:
        "Irrespective of your age and fitness status, it is recommended that you consult your physician and undergo complete medical examination to assess your suitability to participate in the Event.",
    },
    {
      condition:
        "You agree that Freshworks Chennai Marathon 2025 Organizing Committee, Chennai Runners and associated companies or entities that organize the Event shall not be liable for any loss, damage, illness or injury that might occur as a result of your participation in the Event.",
    },
    {
      condition:
        "You agree that you are aware of all risks associated with participating in this Event including, but not limited to, falls, contact with other participants, the effects of the weather, including high heat or humidity, traffic and the condition of the road, arson or terrorist threats and all other risks associated with a public event.",
    },
    {
      condition:
        "You agree that the Thamizhottam 2024 Organizing Committee,  Thamizhthadam Arakkattalai and associated companies or entities that organize the Event shall not be liable for any loss, damage, illness or injury that might occur as a result of your participation in the Event.",
    },
    {
      condition:
        "You agree to abide by the instructions provided by the Organizers from time to time in the best interest of your health and Event safety.",
    },
    {
      condition:
        "You agree to dress appropriately for the Event. Inappropriate clothing includes, but not limited to, clothing or gear dangerous to other participants, unpleasant to other participants, carrying messages containing political or religious propaganda and advertising an individual name or organization that the Event Organizer does not acknowledge.",
    },
    {
      condition:
        "You agree that you will not use the event to promote or communicate by any means political or religious propaganda and advertising an individual name or organization that the Event Organizer does not acknowledge.",
    },
    {
      condition:
        "You also agree to stop running if instructed by the Event Organizers or the medical staff or by the aid station volunteers.",
    },
    {
      condition:
        "You confirm that, in the event of adverse weather conditions, major incidents or threats on the day, the organizers reserve the right to stop/cancel/postpone the Event. You understand that confirmed registrations and merchandise orders are non- refundable, non-transferable and cannot be modified.",
    },
    {
      condition:
        "Copyright of images, photographs, articles, race records, and location information, covering the Event, and their usage right for TV broadcasting, newspapers, magazines and the Internet, belongs to the Event Organizer. Aforementioned copyright includes but is not limited to names and other personal information such as age and address of participants mentioned in the article covering the event. You confirm that your name and media recordings taken during your participation may be used to publicize the Event at any time by the Organizers.",
    },
    {
      condition:
        "You acknowledge and agree that your personal information can be stored and used by Thamizhthadam Arakkattalai or any other company in connection with the organization, promotion and administration of the Event and for the compilation of statistical information.",
    },
    {
      condition:
        "You confirm that, in the event of adverse weather conditions, major incidents or threats on the day, the organizers reserve the right to stop/cancel/postpone the Event. You understand that confirmed registrations and merchandise orders are non- refundable, non-transferable and cannot be modified.",
    },
    {
      condition:
        "The organizers reserve the right to reject any application without providing reasons. Any amount collected from rejected applications alone will be refunded in full (excluding bank charges wherever applicable).",
    },
    {
      condition:
        "Thamizhottam 2024 Team will communicate the Cut Off and Event Closure time before the race day. Participants will not be allowed to stay on the course beyond the stipulated cut-off time for an event.",
    },
    {
      condition:
        "It is mandatory for confirmed participants to visit the Expo to collect their running bib. If a participant is unable to attend the Expo due to unavoidable reasons, an authorized representative may collect the running bib on their behalf. Detailed instructions in this regard will be sent by email in due course to all participants.",
    },
    {
      condition:
        "We will be sending regular updates to the mobile number you have provided in the registration form. This should not be treated as spam and you shall not take any action against our bulk sms / whatsapp / email service provider and / or the organizers and partners.",
    },
  ];
  const [termsConditions, setTermsConditions] = useState([]);
  useEffect(() => {
    const savedLang = localStorage.getItem("lang");
    if (savedLang === "en") {
      setTermsConditions(englistTermsConditions);
    } else {
      setTermsConditions(tamilTermsConditions);
    }
  }, [t]);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <MarathonNavbar />
      <section className="home-section">
        {/* <form className="language-form">
          <GrLanguage className="lang-icon" />
          <select
            className="lag_select w-100 ml-1 c-pointer"
            {...register("lange")}
            onChange={changeLanguage}
          >
            <option value="en">English</option>
            <option value="ta">தமிழ்</option>
          </select>
        </form> */}

        <div className="logo-section">
          <div
            className="logos-1"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src="/images/Tamiltadam.png" alt="" className="tamil-logo" />
          </div>
          <div
            className="logos-2"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img
              src="/images/Tamil1.jpg"
              alt=""
              className="tamil-logo1 img-fluid"
            />
          </div>
        </div>
      </section>

      <section
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <div className="register-evented">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className="regs-list"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className="regs-list-item"
            >
              <div className="register-event">
                <div>
                  <FaCalendarAlt className="register-icon" />
                </div>
                <div>
                  <span className="registers">{t("Homepage.date")}</span>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className="regs-list-item"
            >
              <div className="register-event">
                <div>
                  <FaRegClock className="register-icon" />
                </div>
                <div>
                  <span className="registers">{t("Homepage.time")}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className="register-event">
                <div>
                  <FaLocationDot className="register-icon" />
                </div>
                <div>
                  <span className="registers">{t("Homepage.location")}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className="register-event4">
                <Link
                  to={"https://sportswander.com/event/MTEx/Thamizhottam"}
                  target="_blank"
                >
                  <div>
                    <IoArrowForwardCircleOutline className="register-icon1" />
                  </div>
                  <div>
                    <span className="registered">{t("Homepage.register")}</span>
                  </div>
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div>
                <img src="/images/run.png" alt="" className="bigstock" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              data-aos="fade-down"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className="register-sectors">
                <h2 className="register-title">
                  <span className="">{t("Homepage.title")} </span> - 2024
                </h2>
                <p className="register-subtitle">{t("Homepage.subtitle")}</p>
                <p className="register-maintitle">{t("Homepage.maintitle")}</p>
                <p className="register-maintitle">{t("Homepage.mainpara")}</p>
                <div className="register-sectoreds">
                  <Link
                    to={"https://sportswander.com/event/MTEx/Thamizhottam"}
                    target="_blank"
                  >
                    <button
                      className="miss-btn"
                      data-aos="fade-up"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="600"
                    >
                      {t("Homepage.register")}
                      <MdOutlineKeyboardDoubleArrowRight className="arow-right" />
                    </button>
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="event-section">
        <div className="event-card-sect">
          <Grid2 container spacing={3} justifyContent="center">
            {cardData.map((card, index) => (
              <Grid2
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                key={index}
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <div>
                  <h2 className="events-title">
                    <span className="">{card.title}</span>
                  </h2>
                </div>
                <Card
                  sx={{ maxWidth: 400 }}
                  className={
                    lang === "en" ? "card-container" : "card-container1"
                  }
                >
                  <div>
                    <CardMedia
                      component="img"
                      height="180%"
                      image={card.image}
                      alt={card.title}
                    />
                  </div>
                  <CardContent className="card-context">
                    <p className="carded-subtitle">{card.description}</p>
                  </CardContent>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        </div>
      </section>

      <section>
        <div className="events-images">
          <div className="even-image">
            <img src="/images/mara-1.png" alt="" className="eve-list" />
          </div>
          <div className="even-image">
            <img src="/images/mara-6.png" alt="" className="eve-list" />
          </div>
          <div className="even-image">
            <img src="/images/mara-2.png" alt="" className="eve-list" />
          </div>
          <div className="even-image">
            <img src="/images/mara-3.png" alt="" className="eve-list" />
          </div>
          <div className="even-image">
            <img src="/images/mara-4.png" alt="" className="eve-list" />
          </div>
          <div className="even-image">
            <img src="/images/mara-5.png" alt="" className="eve-list" />
          </div>
        </div>
      </section>

      <section className="underline-content">
        <div
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <h2 className="underline-title">
            <span className="">{t("Homepage.sponsors")}</span>
          </h2>
        </div>
        <div className="underline-images">
          <div
            className="under-image"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src="/images/sponsors3.jpeg" alt="" className="logo-list" />
          </div>
          <div
            className="under-image"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src="/images/sponsors2.png" alt="" className="logo-list" />
          </div>
          <div
            className="under-image"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src="/images/logo.png" alt="" className="logo-list" />
          </div>
          <div
            className="under-imaged"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src="/images/sponsors1.jpg" alt="" className="logo-list" />
          </div>
        </div>
      </section>

      <section className="underline-content">
        <div
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <h2 className="underline-title">
            <span className="">{t("Homepage.partners")}</span>
          </h2>
        </div>
        <div className="underline-images">
          <div
            className="under-image"
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src="/images/karam-logo.png" alt="" className="logo-list" />
          </div>
          <div
            className="under-image"
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src="/images/Aniyam-Eng-2.png" alt="" className="logo-list" />
          </div>
          <div
            className="under-image"
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src="/images/partner2.png" alt="" className="logo-list" />
          </div>
          <div
            className="under-image"
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src="/images/partner3.png" alt="" className="logo-list" />
          </div>
          <div
            className="under-imaged"
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src="/images/partner4.jpeg" alt="" className="logo-list" />
          </div>
        </div>
      </section>
      <section className="terms_conditions">
        <div className="container">
          <Tabs
            value={value}
            onChange={handleChange}
            // indicatorColor="secondary"
            // textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label={t("termsConditions.title")} {...a11yProps(0)} />
            <Tab label={t("faq.title")} {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <div className="terms_conditions_div">
              <ol>
                {termsConditions &&
                  termsConditions.map((list, index) => (
                    <li key={index} className="terms_conditions_li">
                      {list.condition}
                      <ul>
                        {list.conditionSub &&
                          list.conditionSub.map((data, i) => (
                            <li key={i} className="terms_conditions_ul_li">
                              {data}
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
              </ol>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="terms_conditions_div">
              <h5 className="mb-3">
                <b>{t("faq.titleheader1")}</b>
              </h5>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus1")}</b>
                </p>
                <p>
                  {t("faq.ans11")}{" "}
                  <Link to={"https://www.thamizhthadam.org"} target="_blank">
                    {t("faq.ans12")}
                  </Link>{" "}
                  {t("faq.ans13")}
                </p>
                <p>
                  {t("faq.ans14")}
                  <Link to={"mailto:vanakkam@thamizhthadam.org"}>
                    {t("faq.ans15")}
                  </Link>
                  {t("faq.ans16")}
                </p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus2")}</b>
                </p>
                <p>
                  {t("faq.ans21")}{" "}
                  <Link to={"mailto:vanakkam@thamizhthadam.org"}>
                    {t("faq.ans22")}
                  </Link>{" "}
                  {t("faq.ans23")}
                </p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus3")}</b>
                </p>
                <p>{t("faq.ans3")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus4")}</b>
                </p>
                <p>{t("faq.ans4")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus5")}</b>
                </p>
                <p>{t("faq.ans5")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus6")}</b>
                </p>
                <p>{t("faq.ans6")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus7")}</b>
                </p>
                <p>{t("faq.ans7")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus8")}</b>
                </p>
                <p>{t("faq.ans8")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus9")}</b>
                </p>
                <p>
                  {t("faq.ans091")}{" "}
                  <Link to={"mailto:vanakkam@thamizhthadam.org"}>
                    {t("faq.ans092")}
                  </Link>
                  {t("faq.ans093")}
                </p>
              </div>
              {/* <h5 className="mb-3">
                <b>{t("faq.titleheader2")}</b>
              </h5>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus014")}</b>
                </p>
                <p>{t("faq.ans014")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus015")}</b>
                </p>
                <p>{t("faq.ans015")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus016")}</b>
                </p>
                <p>{t("faq.ans016")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus017")}</b>
                </p>
                <p>{t("faq.ans017")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus018")}</b>
                </p>
                <p>{t("faq.ans018")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus019")}</b>
                </p>
                <p>{t("faq.ans019")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus020")}</b>
                </p>
                <p>{t("faq.ans020")}</p>
              </div>
              <div className="faq_div">
                <p>
                  <b>{t("faq.qus021")}</b>
                </p>
                <p>{t("faq.ans021")}</p>
              </div> */}
            </div>
          </TabPanel>
          {/* <div>
            <div className="mb-3">
              <h2 className="underline-title">
                <span className="">{t("termsConditions.title")}</span>
              </h2>
            </div>
            
          </div>
          <div>
            <div className="mb-3">
              <h2 className="underline-title">
                <span className="">{t("faq.title")}</span>
              </h2>
            </div>
           
          </div> */}
        </div>
      </section>
      <section
        className="marathon-footer1"
        data-aos="fade-up"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <div className="marathon-section">
          <h2 className="marathon-title">
            #{t("Homepage.title")}
            <span className="marathon-num"> 2024</span>
          </h2>
          <div className="marathon-social-icons-wrapper">
            <div className="marathon-socials-links">
              <Link
                to="https://www.facebook.com/thamizhthadam"
                target="_blank"
                className="marathon-socials-icon"
              >
                <FaFacebook />
              </Link>
              <Link
                to="https://x.com/thamizhthadam"
                target="_blank"
                className="marathon-socials-icon"
              >
                <FaXTwitter />
              </Link>
              <Link
                to="https://www.instagram.com/thamizhthadam_/"
                target="_blank"
                className="marathon-socials-icon"
              >
                <FaInstagram />
              </Link>
              <Link
                to="https://www.youtube.com/channel/UCWYiqoxIMbga4Pej5MyfJgg"
                target="_blank"
                className="marathon-socials-icon"
              >
                <FaYoutube />
              </Link>
            </div>
          </div>
          <div className="phone-sects">
            <div className="phone-logs">
              <p className="phone-cont">{t("Homepage.contact")}: </p>
              <FaPhone className="phones-logo" />
              <p className="phoned">+91 9345330414</p>
            </div>
          </div>
        </div>
      </section>
      <section className="marathon-footer3">
        {lang === "en" ? (
          <div className="marathon-section">
            <p className="marathon-last">
              {t("Homepage.copyrights")}{" "}
              <span>
                <img src="/images/Amizhth.png" alt="" className="amizhth" />
              </span>
            </p>
          </div>
        ) : (
          <div className="marathon-section">
            <p className="marathon-last">
              <span>
                <img src="/images/Amizhth.png" alt="" className="amizhth" />
              </span>{" "}
              {t("Homepage.copyrights")}
            </p>
          </div>
        )}
      </section>
    </div>
  );
};

export default MarathonHome;
