import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { GrLanguage } from "react-icons/gr";
import $ from "jquery";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
const MarathonNavbar = () => {
  const { register, setValue } = useForm({});
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  let openValue = sessionStorage.getItem("openModal");
  useEffect(() => {
    let lang = localStorage.getItem("lang");
    if (lang === null) {
      setValue("lange", "ta");
    } else {
      setValue("lange", lang);
    }
    // if (openValue === null) {
    //   setOpen(true);
    //   sessionStorage.setItem("openModal", true);
    // }
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  // const lang = localStorage.getItem("lang");
  useEffect(() => {
    const savedLang = localStorage.getItem("lang") || "ta";
    setValue("lange", savedLang);
    debugger;
    i18n.changeLanguage(savedLang);
    $("html").attr("lang", savedLang);
    // document.documentElement.lang = savedLang;
  }, [setValue, i18n]);

  const handleLanguage = (e) => {
    const selectedLang = e.target.value;
    i18n.changeLanguage(selectedLang);
    $("html").attr("lang", selectedLang);
    localStorage.setItem("lang", selectedLang);
    // document.documentElement.lang = selectedLang;
  };
  $(document).ready(function () {
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 90) {
        $(".navbar").addClass("toptotop");
        $(".navbar a.scroll_down").removeClass("d-none");
        $(".navbar a.scroll_down").addClass("d-inline");
        $(".navbar a.scroll_up").removeClass("d-inline");
        $(".navbar a.scroll_up").addClass("d-none");
      } else {
        $(".navbar").removeClass("toptotop");
        $(".navbar a.scroll_down").removeClass("d-inline");
        $(".navbar a.scroll_down").addClass("d-none");
        $(".navbar a.scroll_up").removeClass("d-none");
        $(".navbar a.scroll_up").addClass("d-inline");
        // debugger
      }
    });
  });
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-transparent fixed-top">
      <Link className="scroll_down d-none" to="/">
        <img src="/images/Tamiltadam.png" alt="Logo" className="navbar-logo" />
      </Link>
      <Link className="scroll_down d-none" to="/">
        <img src="/images/Tamil1.jpg" alt="Logo" className="navbar-logo1" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse ml-auto"
        id="navbarSupportedContent"
      >
        <form className="language-form ml-auto">
          {/* <GrLanguage className="lang-icon" /> */}
          <img src="./images/lang.png" alt="" className="lang-icon" />
          <select
            className="lag_select ml-1 c-pointer"
            {...register("lange")}
            onChange={handleLanguage}
          >
            <option value="en">English</option>
            <option value="ta">தமிழ்</option>
          </select>
        </form>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <img
          src="./images/popup-img.jpeg"
          alt=""
          className="img-fluid banner-imga"
        />
        {/* <DialogActions>
          <Button onClick={handleClose}>Book Now</Button>
        </DialogActions> */}
      </Dialog>
    </nav>
  );
};

export default MarathonNavbar;
